<template>
  <div>

    <hb-form
      label="Site ID"
      required
    >
      <HbTextField
        v-if="!connection.Credentials.connected"
        id="site_id"
        name="site_id"
        placeholder="Enter Site ID"
        v-validate="'required|max:45'"
        v-model="connection.Credentials.site_id"
        data-vv-as="Site ID"
        data-vv-scope="gate"
        data-vv-name="site_id"
        :error="errors.collect('gate.site_id').length > 0"
      />
      <span v-if="connection.Credentials.connected">{{connection.Credentials.site_id}}</span>
    </hb-form>

    <hb-form label="Pin Range" required full :auto-layout="false">
      <v-row no-gutters>
          <div class="hb-inline-sentence-text">
              Minimum: <span v-if="connection.Credentials.connected">{{connection.Credentials.pin_min}}</span>
          </div>
          <HbSelect
              v-if="!connection.Credentials.connected"
              box
              condensed
              medium
              placeholder="Select"
              v-model="connection.Credentials.pin_min"
              :items="mins"
              v-validate="'required'"
              data-vv-scope="gate"
              data-vv-name="pin_min"
              data-vv-as="Minimum Pin"
              :error="errors.has('gate.pin_min')"
          />
          
          <div class="hb-inline-sentence-text">
              Maximum: <span v-if="connection.Credentials.connected">{{connection.Credentials.pin_max}}</span>
          </div>
          <HbSelect
              v-if="!connection.Credentials.connected"
              box
              condensed
              medium
              placeholder="Select"
              v-model="connection.Credentials.pin_max"
              :items="maxs"
              v-validate="'required'"
              data-vv-scope="gate"
              data-vv-name="pin_max"
              data-vv-as="Maximum Pin"
              :error="errors.has('gate.pin_max')"
          />
      </v-row>
    </hb-form>

    <hb-form
      v-if="showPinLenght"
      label="Pin Length"
      required
    >
      <HbSelect
        v-if="!connection.Credentials.connected"
        :items="pin_formats"
        id="pin_format"
        name="pin_format"
        placeholder="Select Pin Length"
        v-validate="'required'"
        v-model="connection.Credentials.pin_format"
        data-vv-as="Pin Length"
        data-vv-scope="gate"
        data-vv-name="pin_format"
        :clearable="false"
        :error="errors.collect('gate.pin_format').length > 0"
      />
      <span v-if="connection.Credentials.connected">{{connection.Credentials.pin_format}}</span>
    </hb-form>

    <hb-bottom-action-bar
      :cancel-off="connection.Credentials.connected"
      @close="$emit('cancel')"
      :top-border="false"
      v-resize="setAuditCollapse"
      ref="bottomRemoveBar"
      >
      <template v-slot:left-actions v-if="connection.Credentials.connected && connection.modified_at && connection.modified_by_fullname">
        <hb-tooltip v-if="collapseAudit">
          <template v-slot:item>
            <v-row no-gutters align="center">
              <hb-icon 
                mdi-code="mdi-account-clock-outline"
                small
                color="#101318"  
                class="mr-2 ml-4 mt-0 mb-1"
              >
              </hb-icon>
            </v-row>
          </template>
          <template v-slot:body>
            {{connection.modified_by_fullname}}<br>{{editTime}}
          </template>
        </hb-tooltip>
        <v-row no-gutters align="center" v-else>
          <hb-icon 
            mdi-code="mdi-account-clock-outline"
            small
            color="#101318" 
            class="mr-2 ml-4 mt-0 mb-1"
          >
          </hb-icon>
          <v-col class="py-0 my-0 hb-font-caption">
            {{connection.modified_by_fullname + " (" + editTime + ")"}}
          </v-col>
        </v-row>
      </template>
      <template v-slot:right-actions>
        <hb-btn
          v-if="!connection.Credentials.connected"
          :disabled="!property_id || savingInProgress"
          :loading="savingInProgress"
          @click="save"
        >
          Connect to Sentinel
        </hb-btn>

        <hb-btn
            v-if="connection.Credentials.connected"
            color="secondary"
            :disabled="!connection.access_id || removeInProgress"
            :loading="removeInProgress"
            @click="$emit('confirmFacilitySync')"
        >
          Sync All Tenants Now
        </hb-btn>

        <hb-btn
          v-if="connection.Credentials.connected"
          color="secondary"
          :disabled="!connection.access_id || removeInProgress"
          :loading="removeInProgress"
          @click="$emit('confirmRemoveAccess')"
        >
          <span class="hb-text-error">Remove Integration</span>
        </hb-btn>
      </template>
    </hb-bottom-action-bar>

  </div>
</template>
<script type="text/babel">
import api from "../../../assets/api.js";
import { EventBus } from "../../../EventBus.js";
import { notificationMixin } from  '../../../mixins/notificationMixin.js';

export default {
  name: "Sentinel",
  mixins: [notificationMixin],
  data() {
    return {
      connection: {
        property_id: "",
        access_id: "",
        Credentials: {
          site_id: "",
          modified_by_fullname: "",
          modified_at: "",
          connected: false
        }
      },
      pin_formats: [],
      mins: ["3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      maxs: [],
      showPinLenght: false,
      savingInProgress: false,
      collapseAudit: false
    };
  },
  mounted() {
    if(this.$props.access.Credentials) {
      this.connection = this.$props.access;
    }
  },
  computed: {
     editTime(){
        if(this.connection.modified_at) return this.$options.filters.formatLocalDateTimeServices(this.connection.modified_at);
        return '';
     }
  },
  methods: {
    async fetchData(connected) {
      let r = await api.get(this, api.PROPERTIES + this.property_id + "/access");

      this.connection = r.access;
      if(this.connection.access_id) {
        this.$emit('gateSelection', this.connection.Credentials.connected);
      }

    },
    async save() {
      let gate_status = await this.$validator.validateAll("gate");
      
      if (!gate_status) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return;
      } 

      this.savingInProgress = true;

      var data = {
        property_id: this.$props.property_id,
        access_id: this.$props.selectedGateAccessId,
        Credentials: {
          site_id: this.connection.Credentials.site_id,
          pin_format: this.connection.Credentials.pin_format,
          pin_min: this.connection.Credentials.pin_min,
          pin_max: this.connection.Credentials.pin_max,
        }
      };

      try {

        let r = await api.post(this, api.ACCESS_CONTROL, data);
        let s = await this.fetchData(true);

        if(this.connection.Credentials.connected){
          this.showMessageNotification({ type: 'success', description: 'You have successfully connected to the Sentinel access control integration.' });
        } else {
          this.showMessageNotification({ description: 'There was an error connecting to the Sentinel access control integration. Verify your credentials and try again or contact Customer Success for assistance.' });
        }

      } catch( err ) {
        this.showMessageNotification({ description: err.toString() });
      }

      this.savingInProgress = false;
    },
    populateArray(start, end) {
      const arr = [];
      start = parseInt(start);
      end = parseInt(end);
      for (let i = start; i <= end; i++) {
        arr.push(i.toString());
      }

      return arr;
    },
    setAuditCollapse() {
      let bottomBar = this.$refs.bottomRemoveBar;
      if (bottomBar && bottomBar.$el.offsetWidth > 0 && bottomBar.$el.offsetWidth < 570) {
        this.collapseAudit = true
      } else {
        this.collapseAudit = false
      }
    }
  },
  watch: {
    access() {
      this.connection = this.access;
    },
    'connection.Credentials.pin_min'() {
      this.maxs = this.populateArray(this.connection.Credentials.pin_min, 12)
    },
    'connection.Credentials.pin_max'() {
      this.showPinLenght = true;
      this.pin_formats = this.populateArray(this.connection.Credentials.pin_min, this.connection.Credentials.pin_max)
    },
  },
  props: ["property_id", "access", "selectedGateAccessId", "removeInProgress"]
};
</script>