

<template>
<div>
  <div v-if="!selectedSyncBatch">
    <hb-data-table
        :headers="headers"
        :items="formattedSyncData"
        :loading="loading"
        @click:row="rowClicked"
    >

    </hb-data-table>
  </div>
  <div v-if="selectedSyncBatch">
    <hb-breadcrumb  @click="goBack()">
      Back to Sync Report List
    </hb-breadcrumb>

    <hb-data-table-header
        left-columns="6"

    >
      <template v-slot:header>
        Reference Number: {{ title }}
      </template>
      <template v-slot:description>
        {{ description }}
      </template>
    </hb-data-table-header>

    <div>
      <hb-data-table
      :headers="selectedSyncHeaders"
      :items="selectedSyncData"
      :loading="loading"
      >

        <template v-slot:header.label="{ header }">
          {{ header.text }}
          <span style="position:relative;top:-1px;left:-2px;">
                            <hb-tooltip max-width="400">
                                <template v-slot:body>

                                    <div class="font-weight-medium pb-1">Register Tenant Profile:</div>
                                    <div class="pb-1">Creates a tenant profile in the Access Control System. This action does not grant space access.</div>
                                    <div class="font-weight-medium pb-1">Restore Tenant Access:</div>
                                    <div class="pb-1">Activates a tenant profile from a suspended state.</div>
                                    <div class="font-weight-medium pb-1">Set Space Overlock</div>
                                    <div class="pb-1">Locks a specific space, preventing tenant access.</div>
                                    <div class="font-weight-medium pb-1">Grant Space Access</div>
                                    <div class="pb-1">Grants the the tenant access to a specific space.</div>
                                    <div class="font-weight-medium pb-1">Suspend Tenant Profile</div>
                                    <div class="pb-1">Suspends the tenant profile, removing access to all spaces.</div>

                                </template>
                            </hb-tooltip>
                        </span>
        </template>

        <template v-slot:item.label="{ item }">
          <div :class="{'hb-text-error': item.event_name === 'failure'}">
            <HbIcon v-if="item.event_name === 'failure'" class="icon-margin" small color="#FB4C4C" mdi-code="mdi-alert" />
            <span>{{ item.label }}</span>
          </div>
        </template>

        <template v-slot:item.json_object="{ item }">
          <div :class="[ 'data-synced-margin', {'hb-text-error': item.event_name === 'failure'} ]">
            <div v-if="item.json_object.user_name">Tenant Name: {{ item.json_object.user_name }}</div>
            <div v-if="item.json_object.space_name"> Space Name: {{ item.json_object.space_name }}</div>
            <div v-if="item.json_object.pin"> Pin: {{ item.json_object.pin }}</div>
            <div v-if="item.event_name  === 'failure'">Failed to sync. Please contact support@tenantinc.com</div>
          </div>
        </template>

      </hb-data-table>
    </div>
  </div>
</div>
</template>

<script>
import api from '../../../assets/api';
export default {
  name: 'syncReports',
  props: ["property_id"],
  data() {
    return {
      selection: null,
      selectedSyncBatch: false,
      selectedSyncHeaders: [
        { text: 'Action', value: 'label', width: '100px', sortable: false },
        { text: 'Data Synced', value: 'json_object', width: '160px', sortable: false }
      ],
      headers: [
        { text: 'Sync Reference Number', value: 'formatted_message_group_number', width: '160px', sortable: false },
        { text: 'Synced at', value: 'last_synced_at', width: '160px', sortable: true },
        { text: 'Sync Initiated By', value: 'last_synced_by', width: '160px', sortable: false },
        { text: 'Total Updates', value: 'total_data_synced', align: 'center', width: '160px' },
        {text: 'Total Failures', value: 'total_failures', align: 'center',  width: '160px', sortable: true }
      ],
      selectedSyncData: [],
      syncData: [],
      loading: true,
      title: '',
      description: ''
    }
  },
  async created() {
    await this.fetchData()
  },
  watch: {
    property_id() {
      this.fetchData()
    }
  },
  methods: {
    async fetchData() {
      this.loading = true
      try {
        if (!this.property_id) return
        let data = await api.get(this, api.ACCESS_CONTROL + 'facilities/' + this.property_id + '/sync');
        console.log("SYNC response", data)
        this.syncData = data
        console.log("SYNC DATA", this.syncData)


      } catch (err) {
        console.log("FETCH FAILED", err)
      }

      this.loading = false
    },
    async goBack() {
      this.selectedSyncBatch = false
      this.selectedSyncData = []
      this.fetchData()
    },
    async rowClicked(row) {
      this.selectedSyncBatch = true;
      this.loading = true
      this.title = row.message_group_number?.slice(0, 6)
      this.description = row.last_synced_at

      try {
        let data = await api.get(this, api.ACCESS_CONTROL + 'facilities/' + this.property_id + '/sync' + '?message_group_number=' + row.message_group_number)
        console.log("DATA", data)
        this.selectedSyncData = data
      } catch (err) {
        console.log("FAILED", err)
      }

      this.loading = false

    }
  },
  computed: {
    formattedSyncData() {
      return this.syncData.map(item => {
        return {
          ...item,
          formatted_message_group_number: item.message_group_number.slice(0, 6),
          last_synced_at: this.$options.filters.changeDateTimeFormat(item.last_synced_at)
        };
      });
    }
  }
}
</script>

<style scoped>
.data-synced-margin {
  margin-top: 5px;
  margin-bottom: 5px;
}

.icon-margin {
  margin-right: 5px;
}
</style>