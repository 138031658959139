<template>
  <div>


 
    <hb-form
      label="Client ID"
      required
    >
      <HbTextField
        v-if="!connection.Credentials.connected"
        id="client_id"
        name="client_id"
        placeholder="Enter Client ID"
        v-validate="'required|max:45'"
        v-model="connection.Credentials.client_id"
        data-vv-as="Client ID"
        data-vv-scope="creds"
        data-vv-name="client_id"
        :error="errors.collect('creds.client_id').length > 0"
      />
      <span v-if="connection.Credentials.connected">{{connection.Credentials.client_id}}</span>
    </hb-form>

    <hb-form
      label="Client Secret"
      required
    >
      <HbTextField
        v-if="!connection.Credentials.connected"
        id="client_secret"
        name="client_secret"
        placeholder="Enter Client Secret"
        v-validate="'required|max:45'"
        v-model="connection.Credentials.client_secret"
        data-vv-as="Client Secret"
        data-vv-scope="creds"
        :error="errors.collect('creds.client_secret').length > 0"
      />
      <span v-if="connection.Credentials.connected">
        ***********
      </span>
    </hb-form>
    <hb-form
      label="API Key"
      required
    >
      <HbTextField
        v-if="!connection.Credentials.connected"
        id="api_key"
        name="api_key"
        placeholder="Enter API Key"
        v-validate="'required|max:45'"
        v-model="connection.Credentials.api_key"
        data-vv-as="API Key"
        data-vv-scope="creds"
        data-vv-name="api_key"
        :error="errors.collect('creds.api_key').length > 0"
      />
      <span v-if="connection.Credentials.connected">
        ***********
      </span>
    </hb-form>

    <hb-form label="Pin Range" required full :auto-layout="false">
      <v-row no-gutters>
          <div class="hb-inline-sentence-text">
              Minimum: <span v-if="connection.Credentials.connected">{{connection.Credentials.pin_min}}</span>
          </div>
          <HbSelect
              v-if="!connection.Credentials.connected"
              box
              condensed
              medium
              placeholder="Select"
              v-model="connection.Credentials.pin_min"
              :items="mins"
              v-validate="'required'"
              data-vv-scope="gate"
              data-vv-name="pin_min"
              data-vv-as="Minimum Pin"
              :error="errors.has('gate.pin_min')"
          />
          
          <div class="hb-inline-sentence-text">
              Maximum: <span v-if="connection.Credentials.connected">{{connection.Credentials.pin_max}}</span>
          </div>
          <HbSelect
              v-if="!connection.Credentials.connected"
              box
              condensed
              medium
              placeholder="Select"
              v-model="connection.Credentials.pin_max"
              :items="maxs"
              v-validate="'required'"
              data-vv-scope="gate"
              data-vv-name="pin_max"
              data-vv-as="Maximum Pin"
              :error="errors.has('gate.pin_max')"
          />
      </v-row>
    </hb-form>

    <hb-form
      v-if="showPinLenght"
      label="Pin Length"
      required
    >
      <HbSelect
        v-if="!connection.Credentials.connected"
        :disabled="!!connection.Credentials.brivo_group"
        :items="pin_formats"
        :id="'pin_format'"
        :name="'pin_format'"
        placeholder="Select Pin Length"
        v-validate="'required'"
        v-model.lazy="connection.Credentials.pin_format"
        data-vv-scope="gate"
        data-vv-as="Pin Length"
        :error="errors.collect('gate.pin_format').length > 0"
      />
      <span v-if="connection.Credentials.connected">{{connection.Credentials.pin_format}}</span>
    </hb-form>

    <hb-form
      v-if="connection.Credentials.connected"
      label="Active User Group"
      required
    >
      <HbSelect
        :disabled="!!connection.Credentials.brivo_group"
        :items="groups"
        item-text="name"
        item-value="id"
        :id="'brivo_group'"
        :name="'brivo_group'"
        placeholder="Select Active User Group"
        v-validate="'required'"
        v-model.lazy="selectedBrivoGroup"
        data-vv-scope="gate"
        data-vv-as="Active User Group"
        :error="errors.collect('gate.brivo_group').length > 0"
      />
    </hb-form>

    <hb-bottom-action-bar
      :cancel-off="connection.Credentials.connected"
      @close="$emit('cancel')"
      :top-border="false"
      v-resize="setAuditCollapse"
      ref="bottomRemoveBar"
    >
      <template v-slot:left-actions v-if="connection.Credentials.connected && connection.modified_at && connection.modified_by_fullname">
        <hb-tooltip v-if="collapseAudit">
          <template v-slot:item>
            <v-row no-gutters align="center">
              <hb-icon 
                mdi-code="mdi-account-clock-outline"
                small
                color="#101318"  
                class="mr-2 ml-4 mt-0 mb-1"
              >
              </hb-icon>
            </v-row>
          </template>
          <template v-slot:body>
            {{connection.modified_by_fullname}}<br>{{editTime}}
          </template>
        </hb-tooltip>
        <v-row no-gutters align="center" v-else>
          <hb-icon 
            mdi-code="mdi-account-clock-outline"
            small
            color="#101318" 
            class="mr-2 ml-4 mt-0 mb-1"
          >
          </hb-icon>
          <v-col class="py-0 my-0 hb-font-caption">
            {{connection.modified_by_fullname + " (" + editTime + ")"}}
          </v-col>
        </v-row>
      </template>
      <template v-slot:right-actions>
        <hb-btn
          v-if="!connection.Credentials.connected"
          :disabled="!property_id || savingInProgress"
          :loading="savingInProgress"
          @click="testConnection"
        >
          Test Connection
        </hb-btn>
        <hb-btn
          v-if="!connection.Credentials.connected"
          :disabled="!property_id || savingInProgress"
          :loading="savingInProgress"
          @click="connect"
        >
          Connect to Brivo
        </hb-btn>

        <hb-btn
          v-if="connection.Credentials.connected && !connection.Credentials.brivo_group"
          :disabled="savingInProgress"
          :loading="savingInProgress"
          @click="save(true)"
        >
          Update Integration
        </hb-btn>

        <hb-btn
          v-if="connection.Credentials.connected"
          color="secondary"
          :disabled="!connection.Credentials.connected || removeInProgress"
          :loading="removeInProgress"
          @click="$emit('confirmRemoveAccess')"
        >
          <span class="hb-text-error">Remove Integration</span>
        </hb-btn>
      </template>
    </hb-bottom-action-bar>

  </div>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
import { EventBus } from "../../../EventBus.js";
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
export default {
  name: "BrivoSettings",
  mixins: [notificationMixin],
  data() {
    return {
      connection: {
        property_id: "",
        Credentials: {
          client_id: "",
          client_secret: "",
          api_key: "", 
          pin_format: "4",
          brivo_group: "",
          modified_by_fullname: "",
          modified_at: "",
          connected: false
          
        }
      },
      groups: [],
      selectedBrivoGroup: null,
      pin_formats: [],
      mins: ["3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      maxs: [],
      showPinLenght: false,
      savingInProgress: false,
      collapseAudit: false
    };
  },
  mounted() {
    if(this.$props.access.Credentials) {
      this.connection = this.$props.access;
      if (this.connection.Credentials.connected) {
        if(this.connection.Credentials.brivo_group){
          this.selectedBrivoGroup = parseInt(this.connection.Credentials.brivo_group);
        }
        this.getGroups();
      }
    }
  },
  created(){
    EventBus.$on('brivoConnection', this.fetchData);
  },
  destroyed(){
    EventBus.$off("brivoConnection", this.fetchData);
  },
  computed: {
     editTime(){
        if(this.connection.modified_at) return this.$options.filters.formatLocalDateTimeServices(this.connection.modified_at);
        return '';
     }
  },
  methods: {
    async fetchData(connected) {
      let r = await api.get(this, api.PROPERTIES + this.property_id + "/access");

      this.connection = r.access;
      if (this.connection.access_id) {
        this.selectedBrivoGroup = parseInt(this.connection.Credentials.brivo_group);
        this.getGroups();
        this.$emit('gateSelection', this.connection.Credentials.connected);
      }

    },

    getGroups() {
      api
        .get(
          this,
          api.ACCESS_CONTROL +
            "brivo/" +
            this.$props.property_id +
            "/groups"
        )
        .then(r => {
          this.groups = r.groups;
        });
    },
    async connect() {
      
      let gate_status = await this.$validator.validateAll("gate");
      if (!gate_status) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return;
      } 

      this.savingInProgress = true;

      var data = {
        property_id: this.$props.property_id,
        access_id: this.$props.selectedGateAccessId,
        Credentials: {
          client_secret: this.connection.Credentials.client_secret,
          client_id: this.connection.Credentials.client_id,
          api_key: this.connection.Credentials.api_key,
          pin_format: this.connection.Credentials.pin_format,
          pin_min: this.connection.Credentials.pin_min,
          pin_max: this.connection.Credentials.pin_max,
        }
      };

      try {

        let r = await api.post(this, api.ACCESS_CONTROL + "brivo", data);

        window.open(
          "https://auth.brivo.com/oauth/authorize?response_type=code&client_id=" +
            this.connection.Credentials.client_id +
            "&state=" +
            this.$props.property_id,
          "",
          "width=600,height=400"
        );
        
        let s = await this.fetchData();

        if(this.connection.Credentials.connected){
          this.showMessageNotification({ type: 'success', description: 'You have successfully connected to the Brivo access control integration.' });
        } else {
          this.showMessageNotification({ description: 'There was an error connecting to the Brivo access control integration. Verify your credentials and try again or contact Customer Success for assistance.' });
        }
      } catch( err ) {
        this.showMessageNotification({ description: err.toString() });
      }

      this.savingInProgress = false;
    },
    async save(update = false) {
      let gate_status = await this.$validator.validateAll("gate");

      if (!gate_status) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return;
      } 

      this.savingInProgress = true;

      let data;

      if (update) {
        data = {
          property_id: this.$props.property_id,
          access_id: this.$props.selectedGateAccessId,
          Credentials: {
            brivo_group: this.selectedBrivoGroup
          }
        };
      } else {
        data = {
          property_id: this.$props.property_id,
          access_id: this.$props.selectedGateAccessId,
          Credentials: {
            client_secret: this.connection.Credentials.client_secret,
            client_id: this.connection.Credentials.client_id,
            api_key: this.connection.Credentials.api_key,
            pin_format: this.connection.Credentials.pin_format,
            brivo_group: this.selectedBrivoGroup
          }
        };
      }


      await api.put(this, api.ACCESS_CONTROL + "brivo/" + this.connection.property_id, data);
      this.fetchData(true);

      this.savingInProgress = false;
    },
    async testConnection(){
      let connected = false;
      let gate_status = await this.$validator.validateAll("creds");
      
      if (!gate_status) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return;
      } 

      this.savingInProgress = true;

      let data = {
          property_id: this.$props.property_id,
          access_id: this.$props.selectedGateAccessId,
          Credentials: {
            client_secret: this.connection.Credentials.client_secret,
            client_id: this.connection.Credentials.client_id,
            api_key: this.connection.Credentials.api_key,
          }
        }

      try {

        let r = await api.put(this, api.ACCESS_CONTROL + "facilities/" + this.property_id + "/testConnection", data);
        console.log("TestConnectionResult: ", JSON.parse(JSON.stringify(r)))
        
        if(r?.connected){
          connected = true;
          this.showMessageNotification({ type: 'success', description: 'Connection successfull.' });
        } else {
          this.showMessageNotification({ description: 'Error connecting to Open Tech. Verify your credentials and try again.' });
        }

      } catch( err ) {
        this.showMessageNotification({ description: err.toString() });
      }

      this.savingInProgress = false;
      return connected;

    },
    populateArray(start, end) {
      const arr = [];
      start = parseInt(start);
      end = parseInt(end);
      for (let i = start; i <= end; i++) {
        arr.push(i.toString());
      }

      return arr;
    },
    setAuditCollapse() {
      let bottomBar = this.$refs.bottomRemoveBar;
      if (bottomBar && bottomBar.$el.offsetWidth > 0 && bottomBar.$el.offsetWidth < 695) {
        this.collapseAudit = true
      } else {
        this.collapseAudit = false
      }
    }
  },
  watch: {
    access() {
      this.connection = this.access;
      if (this.connection.Credentials) {
        this.selectedBrivoGroup = parseInt(this.connection.Credentials.brivo_group);
        this.getGroups();
      }
    },
    'connection.Credentials.pin_min'() {
      this.maxs = this.populateArray(this.connection.Credentials.pin_min, 12)
    },
    'connection.Credentials.pin_max'() {
      this.showPinLenght = true;
      this.pin_formats = this.populateArray(this.connection.Credentials.pin_min, this.connection.Credentials.pin_max)
    },
  },
  props: ["property_id", "access", "selectedGateAccessId", "removeInProgress"]
};
</script>

<style lang="scss" scoped>
</style>
